import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import {
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CookieOptions, CookieService } from 'ngx-cookie-service';

import { AuthService } from '../shared/services/auth.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  loginForm = new FormGroup({
    username: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required)
  });
  loginFormSubmitted = false;
  horizontalPosition: MatSnackBarHorizontalPosition = 'end';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  resetPasswordUrl: string = environment.RESET_PASSWORD;
  private readonly _destroying$ = new Subject<null>();
  loginStatus = false;
  loginLoader: string = 'true';

  constructor(
    private authService: AuthService,
    private router: Router,
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private cookieService: CookieService
  ) {};

  ngOnInit() {
    if(this.authService.isMsalLoggedIn()) {
      sessionStorage.setItem('isAuthLoading', 'true');
    }
    else {
      if(!sessionStorage.getItem('isAuthLoading')){
        sessionStorage.setItem('isAuthLoading', 'false');
      }
      this.loginLoader = sessionStorage.getItem('isAuthLoading')!;
    }

    if(this.authService.isUserLoggedIn$.getValue()) {
      this.router.navigate(['/home']);
    }

    this.msalService.handleRedirectObservable().subscribe({
      next: (result) => {
        if (!result) {
          this.setSessionStorageAndLoader("false");
        }
      },
      error: () => {
        this.setSessionStorageAndLoader("false");
      }
    });

    this.msalBroadcastService.msalSubject$
    .pipe(
      filter((msg: EventMessage) => msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED),
    )
    .subscribe((result: EventMessage) => {
      if (this.msalService.instance.getAllAccounts().length === 0) {
        this.router.navigate(['/login']);
      } else {
        this.setLoginDisplay();
      }
    });

    this.msalBroadcastService.inProgress$
    .pipe(
      filter((status: InteractionStatus) => status === InteractionStatus.None),
      takeUntil(this._destroying$)
    )
    .subscribe(() => {
      this.setLoginDisplay();
    })
  }

  onSubmit(){
    if(this.loginForm.valid) {
      this.loginFormSubmitted = true;
      this.authService.login({
        username: this.loginForm.value['username']!,
        password: this.loginForm.value['password']!
      })
      .subscribe({
        next: () => {
          this.authService.isUserLoggedIn$.next(true);
          this.router.navigate(['/home'])
        },
        error: () => {
          this.loginFormSubmitted = false;
        }
      });
      this.router.navigate(['/home']);
    }
  }

  onSsoLogin() {
    this.setSessionStorageAndLoader("true");
    try {
      this.authService.loginSso();
    } catch (error) {
      this.setSessionStorageAndLoader("false");
    }
  }

  async setLoginDisplay() {

    this.loginStatus = this.msalService.instance.getAllAccounts().length > 0;

    if(this.loginStatus){
      const profile_data = this.msalService.instance.getAllAccounts()[0];
      this.msalService.instance.setActiveAccount(profile_data);

      try {
        if (!this.authService.isMsalLoggedIn()) {
          this.setMsalToken();
        }
        else {
          this.loginUsingSso();
        }
      } catch (error) {
        this.setSessionStorageAndLoader("false");
      }
    } else {
      if(this.authService.isMsalLoggedIn()) {
        await this.onSsoLogin();

        this.setSessionStorageAndLoader("false");
      }
      this.router.navigate(['/login']);
    }
  }

  setMsalToken() {
    const tokenRequest = {
        scopes: ['user.read'],
        account: this.msalService.instance.getAllAccounts()[0]
    }

    this.msalService.acquireTokenSilent(tokenRequest).subscribe((tokenResponse) => {
        if (tokenRequest != null) {
          const domain = this.getDomainFromURL(window.location.hostname);
          const expiry = new Date((new Date()).getTime() + 3600 * 1000);
          let cookieOptions: CookieOptions = {
            domain: domain,
            secure: true,
            sameSite: 'None',
            expires: expiry
          }
          this.cookieService.set(
            'msal-access-token', 
            JSON.stringify({token: tokenResponse.accessToken, expiry: expiry}), cookieOptions
          );
          this.cookieService.set(
            'msal-id-token', 
            JSON.stringify({token: tokenResponse.idToken, expiry: expiry}), cookieOptions
          );

          this.loginUsingSso();
        }
    })
  }

  loginUsingSso() {
    this.authService.loginUsingSso({
      accessToken: JSON.parse(this.cookieService.get('msal-access-token')).token,
      idToken: JSON.parse(this.cookieService.get('msal-id-token')).token,
      email: this.msalService.instance.getActiveAccount()?.username!
    }).subscribe({
      next: () => {
        this.authService.isUserLoggedIn$.next(true);
        this.setSessionStorageAndLoader("false");
        this.router.navigate(['/home']);
      },
      error: () => {
        this.setSessionStorageAndLoader("false");
      }
    });
  }

  private setSessionStorageAndLoader(value: string) {
    this.loginLoader = value;
    sessionStorage.setItem('isAuthLoading', value);
  }

  private getDomainFromURL(url: string) {
    const parts = url.split('.');
    if(parts.length > 2) 
      return parts.slice(-2).join('.');
    else
      return parts[0];
  }

  ngOnDestroy(): void {
    this._destroying$.next(null);
    this._destroying$.complete();
  }
}
