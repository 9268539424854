import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './shared/auth.guard';

const routes: Routes = [
  { 
    path: '', 
    redirectTo: 'login', 
    pathMatch: 'full'
  },
  { 
    path: 'login', 
    component: LoginComponent 
  },
  { 
    path: 'home',
    loadChildren: () => 
      import('./home/home.module')
      .then(m => m.HomeModule),
    canActivate: [AuthGuard]
  },
  { 
    path: 'employees',
    loadChildren: () => 
      import('./employees/employees.module')
      .then(m => m.EmployeesModule),
    canActivate: [AuthGuard]
  },
  { 
    path: 'time-off',
    loadChildren: () => 
      import('./time-off/time-off.module')
      .then(m => m.TimeOffModule),
    canActivate: [AuthGuard]
  },
  { 
    path: 'approvals',
    loadChildren: () => 
      import('./time-off-approvals/time-off-approvals.module')
      .then(m => m.TimeOffApprovalsModule),
    canActivate: [AuthGuard]
  },
  { 
    path: 'attendance',
    loadChildren: () => 
      import('./attendance/attendance.module')
      .then(m => m.AttendanceModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'events',
    loadChildren: () =>
      import('./wg-events/wg-events.module')
        .then(m => m.WgEventsModule),
    canActivate: [AuthGuard]
  },
  { 
    path: 'holidays',
    loadChildren: () => 
      import('./holiday/holiday.module')
      .then(m => m.HolidayModule),
    canActivate: [AuthGuard]
  },
  {
    path: '**',
    redirectTo: 'home'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
