import { Injectable } from "@angular/core";
import { BackendService } from "../shared/services/backend.service";
import { BehaviorSubject } from "rxjs";
import { AppliedTimeOff } from "../shared/models/time-off.model";
import { AuthService } from "../shared/services/auth.service";
import { TimeOffInfoComponent } from "../shared/components/time-off-info/time-off-info.component";
import { MatDialog } from "@angular/material/dialog";

@Injectable({
    providedIn: 'root'
})
export class TimeOffApprovalsService {
    pendingApprovalsLoading$ = new BehaviorSubject<boolean>(true);
    pendingApprovalsDetails$ = new BehaviorSubject<AppliedTimeOff[]>([]);

    pendingApprovalsManagerLoading$ = new BehaviorSubject<boolean>(true);
    pendingApprovalsManagerDetails$ = new BehaviorSubject<AppliedTimeOff[]>([]);

    approvalHistoryLoading$ = new BehaviorSubject<boolean>(true);
    approvalHistoryDetails$ = new BehaviorSubject<AppliedTimeOff[]>([]);

    employeeEmail: string | undefined = '';
  
    constructor(
        private backendService: BackendService,
        private authService: AuthService,
        private dialog: MatDialog
      ) {}

    getPendingApprovalsList() {
        this.pendingApprovalsLoading$.next(true);

        const email = this.getEmail();

        this.backendService.getPendingTimeOffApprovalsList(email).subscribe({
            next: (response: AppliedTimeOff[]) => {
            this.pendingApprovalsLoading$.next(false);
            this.pendingApprovalsDetails$.next(response);
            }
        });
    }
    
    getPendingApprovalsManagerList() {
        this.pendingApprovalsManagerLoading$.next(true);

        this.backendService.getPendingTimeOffApprovalsManagerList().subscribe({
            next: (response: AppliedTimeOff[]) => {
            this.pendingApprovalsManagerLoading$.next(false);
            this.pendingApprovalsManagerDetails$.next(response);
            }
        });
    }

    getApprovalsHistory() {
      this.approvalHistoryLoading$.next(true);
  
      const email = this.getEmail();
  
      this.backendService.getApprovalsHistory(email).subscribe({
        next: (response: AppliedTimeOff[]) => {
          this.approvalHistoryLoading$.next(false);
          this.approvalHistoryDetails$.next(response);
        }
      })
    }

    loadApprovalsData() {
      this.getApprovalsHistory();
      this.getPendingApprovalsList();
      if (this.authService.checkPermission('approval_pending_manager_records')){
        this.getPendingApprovalsManagerList();
      }
    }

    getEmail() {
        return this.employeeEmail === null || this.employeeEmail === undefined || this.employeeEmail === '' 
            ? this.authService.getUserSessionData('EMAIL') 
            : this.employeeEmail;
    }

    openTimeOffDetailsDialogBox(timeOff: AppliedTimeOff, mode: string) {
        this.dialog.open(TimeOffInfoComponent, {
          width: '1000px',
          data: {
            mode: mode,
            timeOff
          }
        });
      }
}