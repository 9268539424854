import { Inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";

import { RedirectRequest } from "@azure/msal-browser";
import jwt_decode from "jwt-decode";
import { BehaviorSubject } from "rxjs";
import { MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalService } from "@azure/msal-angular";

import { LoginRequest, LoginSsoRequest } from "../models/external/login-request.model";
import { UserSession } from "../models/user-session.model";
import { StaticData } from "../models/static-data.model";
import { JwtToken } from "../models/jwt-token.model";
import { BackendService } from "./backend.service";
import { CookieOptions, CookieService } from "ngx-cookie-service";

export const InterceptorSkip = 'X-Skip-Interceptor';
export const TokenSessionStorageKey = 'wgteam-token-session'
export const UserSessionStorageKey = 'wgteam-user-session';
export const StaticSessionStorageKey = 'wgteam-static-data';

@Injectable({ providedIn: 'root' })
export class AuthService {
    isUserLoggedIn$ = new BehaviorSubject<boolean>(false);

    sessionItems = [
        TokenSessionStorageKey,
        UserSessionStorageKey, 
        StaticSessionStorageKey
    ];

    constructor(
        private router: Router,
        private backendService: BackendService,
        @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration, 
        private msalService: MsalService,
        private cookieService: CookieService
    ) {
        this.isUserLoggedIn$.next(this.isLoggedIn());
    }

    login(loginData: LoginRequest) {
        return this.backendService.login(loginData);
    }

    loginSso() {
        if (this.msalGuardConfig.authRequest) {
            this.msalService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
        } else {
            this.msalService.loginRedirect();
        }
    }

    loginUsingSso(loginData: LoginSsoRequest) {
        return this.backendService.loginUsingSso(loginData);
    }

    logout(): void {
        sessionStorage.clear();
        this.isUserLoggedIn$.next(false);
        this.router.navigate(['/login']);
    }

    getUserSessionData(value: keyof UserSession): string {
        const session: UserSession = JSON.parse(sessionStorage.getItem(UserSessionStorageKey)!);
        return session[value];
    }

    getToken(): string {
        return sessionStorage.getItem(TokenSessionStorageKey)!;
    }

    getTokenData(value: keyof JwtToken): string {
        const jwt: string = this.getToken();
        const decodedJwt: any = jwt_decode(jwt);
        return decodedJwt[value];
    }

    getStaticData(value: keyof StaticData): any {
        const staticSessionData: StaticData = JSON.parse(sessionStorage.getItem(StaticSessionStorageKey)!);
        return staticSessionData[value];
    }

    checkPermission(permissionName: string): boolean {
        let permissionList = this.getStaticData('UI_PERMS');
        
        if(permissionList !== undefined && permissionList.length == 0){
            return false;
        }
        
        return permissionList.includes(permissionName);
    }

    isLoggedIn(): boolean {
        const session: UserSession = JSON.parse(sessionStorage.getItem(UserSessionStorageKey)!);
        
        if (session === null) {
            return false;
        }

        const expiry: any = +this.getTokenData('exp');
        const timeExpired = new Date().getTime() > expiry * 1000;

        return !timeExpired;
    }

    isMsalLoggedIn(): boolean {
        let msalAccessToken = this.cookieService.get('msal-access-token')!;
        let msalIdToken = this.cookieService.get('msal-id-token')!;

        if (msalAccessToken && msalIdToken) {
            try {
              let _ = JSON.parse(msalAccessToken).expiry;
              return true;
            }
            catch (error) {
              return false;
            } 
        }
        return false;
    }
}
