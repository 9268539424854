<mat-toolbar color="accent" class="toolbar">
    <nav>
        <a 
            *ngFor="let screen of screens"
            mat-button
            [routerLink]="[screen | lowercase]"
            [routerLinkActive]="'nav-active'">
            {{ screen }}
        </a>
    </nav>
</mat-toolbar>
