import { Component } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { TimeOffGroup } from 'src/app/shared/models/static-data.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { TimeOffService } from '../time-off.service';
import { MatDialogRef } from '@angular/material/dialog';
import { ApplyTimeOffRequest, TimeOffDatesGroup, TimeOffSummary } from 'src/app/shared/models/time-off.model';
import { formatDateToString } from 'src/app/shared/utils';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSelectChange } from '@angular/material/select';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-apply-time-off',
  templateUrl: './apply.component.html',
  styleUrls: ['./apply.component.scss']
})
export class ApplyTimeOffComponent {
  isSubmitted = false;
  timeOffTypes!: TimeOffGroup[];
  dateRange: Date[] = [];
  timeOffAvailableCount = 0;
  applyTimeOffError = '';
  applicableTimeOff!: string[];

  applyTimeOffForm = new FormGroup({
    type: new FormControl('', Validators.required),
    fromDate: new FormControl('', Validators.required),
    toDate: new FormControl('', Validators.required),
    reason: new FormControl('', Validators.required),
    timeoffDates: new FormArray([
      new FormGroup({
        date: new FormControl('', Validators.required),
        quantity: new FormControl('1', Validators.required)
      })
    ])
  });

  constructor(
    private authService: AuthService,
    private timeOffService: TimeOffService,
    private dialogRef: MatDialogRef<ApplyTimeOffComponent>,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.timeOffTypes = this.authService.getStaticData('TIMEOFF_TYPES');

    this.timeOffService.applicableLeavesDetails$.subscribe({
      next: (response) => {
        this.applicableTimeOff = response;
        this.timeOffTypes = this.getFilteredApplicableLeaves(this.timeOffTypes, this.applicableTimeOff);
      }
    })

    this.applyTimeOffForm.valueChanges.subscribe(x => {
      this.applyTimeOffError = '';
    })

  }

  get timeoffDates(): FormArray {
    return this.applyTimeOffForm.get('timeoffDates') as FormArray;
  }

  dateRangeChange(dateRangeStart: HTMLInputElement, dateRangeEnd: HTMLInputElement) {
    let fromDate = dateRangeStart.value;
    let toDate = dateRangeEnd.value;

    let holidayObject = this.authService.getStaticData('HOLIDAYS');
    this.dateRange = this.getDateRange(fromDate, toDate);
    this.timeoffDates.clear();

    this.dateRange.forEach(dateVal => {
      let dayOfWeek = dateVal.getDay();

      if(dayOfWeek === 0 || dayOfWeek  === 6) {
        this.timeoffDates.push(
          new FormGroup({
            date: new FormControl(formatDateToString(dateVal), Validators.required),
            quantity: new FormControl({value: 'WEEKEND', disabled: true}, Validators.required)
          })
        );
      }
      else if(Object.entries(holidayObject).find(([key, value]) => key === formatDateToString(dateVal))) {
        const holidayDescription = `Holiday - ${holidayObject[formatDateToString(dateVal)]}`;
        this.timeoffDates.push(
          new FormGroup({
            date: new FormControl(formatDateToString(dateVal), Validators.required),
            quantity: new FormControl({value: holidayDescription, disabled: true}, Validators.required)
          })
        );
      }
      else {
        this.timeoffDates.push(
          new FormGroup({
            date: new FormControl(formatDateToString(dateVal), Validators.required),
            quantity: new FormControl('1', Validators.required)
          })
        )
      }
    });
  }

  onSubmit() {
    if(!this.applyTimeOffForm.valid) {
      return;
    }

    this.isSubmitted = true;

    let timeOffDatesAndQty: TimeOffDatesGroup[] = [];

    this.applyTimeOffForm.getRawValue()['timeoffDates']?.forEach(d => {
      timeOffDatesAndQty.push({
        DATE: d.date!,
        QTY: d.quantity?.toString()!
      })
    })

    const body: ApplyTimeOffRequest = {
      FROM_DATE: formatDateToString(this.applyTimeOffForm.value['fromDate']),
      TO_DATE: formatDateToString(this.applyTimeOffForm.value['toDate']),
      REASON: this.applyTimeOffForm.value['reason']!,
      TIMEOFF_TYPE: this.applyTimeOffForm.value['type']!,
      TIMEOFF_DATES: timeOffDatesAndQty
    };

    this.timeOffService.applyTimeOff(body).subscribe({
      next: (message: string) => {
        this.snackBar.open(message,  '✖', {
          duration: 5000,
          panelClass: ['green-snackbar'],
        });

        this.dialogRef.close();
        this.timeOffService.loadAllData();
      },
      error: (error: HttpErrorResponse) => {
        this.isSubmitted = false;

        if (error.status == 400) {
          this.applyTimeOffError = error.error['message'];
        }
        else if (error.status == 401 || error.status == 403) {
          this.applyTimeOffError = 'Unauthorized. You are not allowed for this action.';
        }
        else {
          this.applyTimeOffError = 'Something went wrong. Please contact the admin.';
        }
      }
    })
  }

  getDateRange = (from: string, to: string) => {
    const cFrom = new Date(from);
    const cTo = new Date(to);

    let daysArr = [new Date(cFrom)];
    let tempDate = cFrom;

    while (tempDate < cTo) {
        tempDate.setUTCDate(tempDate.getUTCDate() + 1);
        daysArr.push(new Date(tempDate));
    }

    return daysArr;
  }

  onTimeOffTypeChange($event: MatSelectChange) {
    this.timeOffAvailableCount = this.timeOffService.getTimeOffCount($event.value);
  }

  onClose(){
    this.applyTimeOffError = '';
  }

  private getFilteredApplicableLeaves(timeOffTypes: TimeOffGroup[], applicableTimeOff: string[]) {
    let filteredApplicableTimeOffTypes: TimeOffGroup[];

    filteredApplicableTimeOffTypes = timeOffTypes.filter((leave) =>
      applicableTimeOff.includes(leave.tag)
    );

    return filteredApplicableTimeOffTypes;
  }
}
