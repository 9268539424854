<div class="header">
    <a routerLink="/">
        <img alt="logo" class="main-logo" src="assets/watchguard-teams-portal-logo.svg" />
    </a>

    <div class="user-info" *ngIf="isUserLoggedIn">
        <div class="user-name">{{ loggedInUser }}</div>
        <a 
            mat-stroked-button 
            color="primary" 
            class="logout-button"
            (click)="onLogout()">
            Logout
        </a>
    </div>
</div>
