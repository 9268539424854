<form
    [formGroup]="applyTimeOffForm"
    (submit)="onSubmit()"
>
    <div class="header">
        <h3><span>Apply Time Off</span></h3>
        <mat-icon mat-dialog-close>close</mat-icon>
    </div>
    <mat-dialog-content>
        <div class="time-off-form">
            <div class="left-content">
                <mat-form-field appearance="outline" class="input-full-width">
                    <mat-label>Time-Off Type</mat-label>
                    <mat-select 
                        formControlName="type"
                        (selectionChange)="onTimeOffTypeChange($event)"
                    >
                      <mat-option
                        *ngFor="let timeOffType of timeOffTypes"
                        [value]="timeOffType.tag">
                        {{ timeOffType.friendly_name }}
                      </mat-option>
                    </mat-select>
                    <mat-error
                        *ngIf="applyTimeOffForm.invalid && (applyTimeOffForm.dirty || applyTimeOffForm.touched)">
                        Please Select the type of Time-Off
                    </mat-error>
                </mat-form-field>

                <div
                    class="available-leaves"
                >Available Leaves: {{ timeOffAvailableCount }}</div>

                <mat-form-field appearance="outline" class="input-full-width">
                    <mat-label>Date range</mat-label>
                    <mat-date-range-input [formGroup]="applyTimeOffForm" [rangePicker]="picker">
                        <input
                            matStartDate
                            formControlName="fromDate"
                            placeholder="From date"
                            #dateRangeStart
                        >
                        <input
                            matEndDate
                            formControlName="toDate"
                            placeholder="To date"
                            #dateRangeEnd
                            (dateChange)="dateRangeChange(dateRangeStart, dateRangeEnd)"
                        >
                    </mat-date-range-input>
                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>

                    <mat-error
                        *ngIf="applyTimeOffForm.invalid && (applyTimeOffForm.dirty || applyTimeOffForm.touched)">
                        Invalid Time Off Dates
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" class="input-full-width">
                    <mat-label>Reason</mat-label>
                    <textarea matInput formControlName="reason" maxlength="200"></textarea>
                    <mat-error
                        *ngIf="applyTimeOffForm.invalid && (applyTimeOffForm.dirty || applyTimeOffForm.touched)">
                        Reason is required
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="right-content">
                <div
                    formArrayName="timeoffDates"
                    *ngIf="dateRange.length > 0"
                >
                    <div
                        *ngFor="let timeoffDate of timeoffDates?.controls; let i = index"
                        [formGroupName]="i"
                    >
                        <mat-form-field appearance="outline" class="input-full-width">
                            <mat-label>{{ timeoffDate.value['date'] }}</mat-label>
                            <mat-select 
                                formControlName="quantity"
                            >
                                <mat-option [value]="'1'">Full Day (1)</mat-option>
                                <mat-option [value]="'0.5'">Half Day (0.5)</mat-option>
                                <mat-option
                                    *ngIf="timeoffDates.getRawValue()[i]['quantity'] === 'WEEKEND'"
                                    [value]="'WEEKEND'"
                                >Weekend</mat-option>
                                <mat-option
                                    *ngIf="timeoffDates.getRawValue()[i]['quantity'].includes('Holiday')"
                                    [value]="timeoffDates.getRawValue()[i]['quantity']"
                                >{{ timeoffDates.getRawValue()[i]['quantity']}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="isSubmitted === false && applyTimeOffError !== '' && 
            applyTimeOffError !== null && applyTimeOffError !== undefined">
            <app-message [message]="applyTimeOffError" [messageType]="'error'" (closed)="onClose()"></app-message>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
        <button
            type="submit"
            mat-raised-button
            color="primary"
            class="time-off-action-button"
            [disabled]="applyTimeOffForm.invalid || isSubmitted"
            >
            <span *ngIf="isSubmitted"><mat-spinner diameter="20"></mat-spinner></span>
            <span *ngIf="!isSubmitted">Apply Time Off</span>
        </button>
        <button mat-stroked-button mat-dialog-close>Close</button>
    </mat-dialog-actions>
</form>
